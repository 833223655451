/* eslint-disable */
import {ENTITY_TYPE} from "src/constants/enums";
import {RequiredFields} from "src/models/RequiredFields";
import React from "react";
import {Avatar} from "@amzn/stencil-react-components/avatar";
import {IconCheck} from "@amzn/stencil-react-components/icons";
interface InputElement {
    labelText: string,
    id: string,
    disabled: boolean,
    placeholderText?: string,
}

const viewUserInPhoneTool = ({data}) => {
    return <Avatar
        username={data}
        showImage={false}
    />
}

const displayListOfStrings = ({data}: {data: string[]}) => {
    if (data) {
        return <div>{data.join(',')}</div>
    }
    return <div></div>
}

const displayBooleanAsIcon = ({data}: {data: boolean}) => {
    if (data) {
        return <div>{data ? <IconCheck/> : undefined}</div>
    }
    return <div></div>
}

export const EditEntityModalInputElements: Record<string, Array<InputElement>> = {
    [ENTITY_TYPE.QUEUE]: [
        {
            labelText: "Queue ID",
            id: "queueId",
            disabled: true,
        },
        {
            labelText: "Queue Name",
            id: "name",
            disabled: true,
        },
        {
            labelText: "Description",
            id: "description",
            disabled: false,
        },
    ],
    [ENTITY_TYPE.USER]: [
        {
            labelText: "User ID",
            id: "userId",
            disabled: true,
        },
        {
            labelText: "Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Username",
            id: "userName",
            disabled: false,
        },
        {
            labelText: "Employee ID",
            id: "employeeId",
            disabled: false,
        }
    ],
    [ENTITY_TYPE.PROFILE]: [
        {
            labelText: "Profile ID",
            id: "profileId",
            disabled: true,
        },
        {
            labelText: "Profile Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Title",
            id: "title",
            disabled: false,
        },
        {
            labelText: "Description",
            id: "description",
            disabled: false,
        }
    ],
    [ENTITY_TYPE.PROFILE_PERMISSIONS]: [
        {
            labelText: "Field ID",
            id: "fieldId",
            disabled: true,
        }
    ],
    [ENTITY_TYPE.USER_GROUPS]: [
        {
            labelText: "UserGroup Id",
            id: "userGroupId",
            disabled: true,
        },
        {
            labelText: "UserGroup Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Description",
            id: "description",
            disabled: false,
        }
    ]
}

export const CreateEntityModalInputElements: Record<string, Array<InputElement>> = {
    [ENTITY_TYPE.QUEUE]: [
        {
            labelText: "Queue ID",
            id: "queueId",
            disabled: false,
            placeholderText: "(Can leave blank)",
        },
        {
            labelText: "Queue Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Description",
            id: "description",
            disabled: false,
        },
    ],
    [ENTITY_TYPE.USER]: [
        {
            labelText: "Id",
            id: "userId",
            disabled: false,
            placeholderText: "(Can leave blank)",
        },
        {
            labelText: "Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Username",
            id: "userName",
            disabled: false,
        },
        {
            labelText: "Employee ID",
            id: "employeeId",
            disabled: false,
        },
    ],
    [ENTITY_TYPE.PROFILE]: [
        {
            labelText: "Profile Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Title",
            id: "title",
            disabled: false,
        },
        {
            labelText: "Description",
            id: "description",
            disabled: false,
        }
    ],
    [ENTITY_TYPE.PROFILE_PERMISSIONS]: [
        {
            labelText: "Field ID",
            id: "fieldId",
            disabled: false,
        },
    ],
    [ENTITY_TYPE.USER_GROUPS]: [
        {
            labelText: "UserGroup Name",
            id: "name",
            disabled: false,
        },
        {
            labelText: "Description",
            id: "description",
            disabled: false,
        },
    ]
}

export const TableRequiredFields: Record<string, Array<RequiredFields>>  = {
    [ENTITY_TYPE.QUEUE]: [
        { header: "Name", accessor: "name" },
        { header: "Queue ID", accessor: "queueId" },
        { header: "Description", accessor: "description" },
        { header: "Additional Attributes", accessor: "additionalAttributes" },
    ],
    [ENTITY_TYPE.USER]: [
        { header: "Name", accessor: "name" },
        { header: "Username", accessor: "userName", cellComponent: viewUserInPhoneTool },
        { header: "User ID", accessor: "userId" },
        { header: "Employee ID", accessor: "employeeId", cellComponent: viewUserInPhoneTool },
        { header: "Support Countries", accessor: "supportCountries", cellComponent: displayListOfStrings },
        { header: "HR For HR", accessor: "isHrForHr", cellComponent: displayBooleanAsIcon },
        { header: "Primary Domain", accessor: "primaryDomain"},
    ],
    [ENTITY_TYPE.PROFILE]: [
        { header: "Name", accessor: "name" },
        { header: "Profile ID", accessor: "profileId" },
        { header: "Title", accessor: "title" },
        { header: "Description", accessor: "description" }
    ],
    [ENTITY_TYPE.PROFILE_PERMISSIONS] : [
        { header: "Field ID", accessor: "fieldId" },
        { header: "Permission Type", accessor: "permissionType" },
        { header: "Permissions", accessor: "permissions", cellComponent: displayListOfStrings}
    ],
    [ENTITY_TYPE.USER_GROUPS] : [
        { header: "Name", accessor: "name" },
        { header: "User Group Id", accessor: "userGroupId" },
        { header: "Description", accessor: "description" },
        { header: "Additional Attributes", accessor: "additionalAttributes" }
    ],
    [ENTITY_TYPE.QUEUE_TO_USER_MAPPINGS] : [
        { header: "Queue ID", accessor: "queueId" },
        { header: "Name", accessor: "name" },
        { header: "Employee Ids", accessor: "employeeIds" }
    ],
    [ENTITY_TYPE.USER_GROUP_TO_USER_MAPPINGS] : [
        { header: "User Group ID", accessor: "userGroupId" },
        { header: "Name", accessor: "name" },
        { header: "Employee Ids", accessor: "employeeIds" }
    ],
    [ENTITY_TYPE.TRD_MAPPINGS] : [
        { header: "Case Type", accessor: "CaseType" },
        { header: "Contact Driver", accessor: "ContactDriver" },
        { header: "Country", accessor: "Country" }
    ],
    [ENTITY_TYPE.CASE_ENTITY] : [
        { header: "Tracking Id", accessor: "trackingId" },
        { header: "Created Date", accessor: "createdDate" },
        { header: "Status", accessor: "status" },
        { header: "Downloadable Link", accessor: "preSignedUrl" }
    ],
}

export const BulkFileUploadExpectedHeaders: Record<string, Array<string>> = {
    [ENTITY_TYPE.USER]: ["name", "userName", "employeeId", "profileId", "primaryDomain","isHrForHr","supportCountries","userId"],
    [ENTITY_TYPE.PROFILE]: ["name", "title", "description", "profileId"],
    [ENTITY_TYPE.QUEUE]: ["name", "description", "queueId"],
    [ENTITY_TYPE.QUEUE_TO_USER_MAPPINGS]: ["queueId", "name", "employeeIds"],
    [ENTITY_TYPE.USER_GROUP_TO_USER_MAPPINGS]: ["userGroupId", "name", "employeeIds"],
    [ENTITY_TYPE.USER_GROUPS]: ["name", "description", "userGroupId"],
    [ENTITY_TYPE.TRD_MAPPINGS]: ["CaseType", "ContactDriver", "Country"],
    [ENTITY_TYPE.CASE_ENTITY]: ["caseId", "caseNumber", "employeeId"],
}